<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Gestione Tipologie Pratiche</span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Elenco Tipologie Pratiche</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div>
          <b-button variant="primary" @click="$refs.practiceAddModal.open()">
            Aggiungi Tipologia Pratica
          </b-button>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" debounce="500"/>
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="practicesProvider"
        :busy="loading"
        :filter="filter"
        :current-page="currentPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        striped
        show-empty
        responsive
        @sort-changed="sortTable"
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle tipologie pratiche...</strong>
          </div>
        </template>

        <template #empty>
            <h5 class="text-primary-light text-center my-2">Non ci sono tipologie pratiche</h5>
        </template>

        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">Non sono stati trovati tipologie pratiche</h5>
        </template>

        <template #cell(opzioni)="data">
          <feather-icon class="cursor-pointer" icon="EditIcon" size="20" @click="$refs.practiceEditModal.open(data.item)" v-b-tooltip.hover.top.v-primary-light="'Modifica'"/>
        </template>

      </b-table>

      <b-pagination
            v-if="practices.count > perPage"
            v-model="currentPage"
            :total-rows="practices.count"
            :per-page="perPage"
            align="right"
            size="sm"
            class="mt-2"
          />
    </b-card>

    <!-- Modali -->

    <practice-add-modal ref="practiceAddModal" @hide="getPractices"></practice-add-modal>
    <practice-edit-modal ref="practiceEditModal"></practice-edit-modal>

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BSpinner,
  BFormInput,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";

import { PracticeAddModal, PracticeEditModal } from "@/components/modals";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BSpinner,
    BFormInput,
    BPagination,
    PracticeAddModal,
    PracticeEditModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "pratices_name",
        label: "Tipologia Pratica",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    filter: null,
    sortBy: "pratices_name",
    sortDesc: false,
    practices: [],

    //Helpers

    perPage: 10,
    currentPage: 1,
    loading: false,
  }),

  methods: {
    async getPractices(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getPractices(request_data);
        this.practices = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

     async practicesProvider(ctx) {
      await this.getPractices();
      return this.practices.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
